import React, { useState } from 'react'
import DefaultLayout from '../components/DefaultLayout'
import { Row, Col, Form, Tabs, Input, Button, Select, Checkbox, DatePicker } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { updateUser } from '../redux/actions/userActions'
import { postEmp } from '../redux/actions/empActions'
import { useHistory } from 'react-router-dom'
import TimeDropDown from '../components/TimeDropDown'
import moment from 'moment';


const { TextArea } = Input;

const { TabPane } = Tabs;

const { Option } = Select;

function EmpSignup() {
    const allworkers = useSelector(state => state.workersReducer).workers;
    const allsites = useSelector(state => state.sitesReducer).sites;

    console.log(allsites);

    const [pwdInfo, setPwdInfo] = useState({})
    const [activeTab, setActiveTab] = useState("0")
    const dispatch = useDispatch()
    const history = useHistory()

    const CheckboxGroup = Checkbox.Group;

    const plainOptions = allworkers.map(x => x.fname);
    // const plainOptions = ['WorkerOne', 'WorkerTwo', 'WorkerThree', 'WorkerFour'];
    const defaultCheckedList = [];

    const [checkedList, setCheckedList] = React.useState(defaultCheckedList);
    const [indeterminate, setIndeterminate] = React.useState(true);
    const [checkAll, setCheckAll] = React.useState(false);

    const onChange = list => {
        setCheckedList(list);
        setIndeterminate(!!list.length && list.length < plainOptions.length);
        setCheckAll(list.length === plainOptions.length);
    };

    const onCheckAllChange = e => {
        setCheckedList(e.target.checked ? plainOptions : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    };


    function onFinalFormFinish(values) {
        setPwdInfo(values)
        const finalObj = { ...pwdInfo, ...values };
        console.log(finalObj)
        // finalObj.date = finalObj.date.format('dddd, MMMM Do YYYY');
        dispatch(postEmp(finalObj));
    }

    return (
        <div>
            <DefaultLayout>
                <Tabs defaultActiveKey='0' activeKey={activeTab}>
                    <TabPane tab='Employee Signup' key='0'>
                        <Form onFinish={onFinalFormFinish} onReset={() => { history.push('/posted') }}>
                            <Row>
                                <Col lg={8} sm={24}>
                                    <div>
                                        <Form.Item name='email' label='Select Employee'>
                                            <Select
                                                defaultValue={'0'}
                                                className="mr-2"
                                                style={{ minWidth: 200 }}
                                            >
                                                <Option value="0" disabled>-- Select Employee --</Option>
                                                {allworkers.map(worker => {
                                                    return <Option value={worker.email} key={worker.email} >{worker.email}</Option>
                                                })}
                                            </Select>
                                        </Form.Item>

                                    </div>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col lg={8} sm={24}>
                                    <Form.Item name='password' rules={[{ required: true }]} label='Password'>
                                        <Input.Password />                                   
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Button htmlType='submit'>Submit</Button>
                            <Button htmlType='reset' type="default" danger className="ml-2">Cancel</Button>
                        </Form>
                    </TabPane>

                </Tabs>
            </DefaultLayout>
        </div>
    )
}

export default EmpSignup
