import { Form, Select } from 'antd'


function TimeDropDown(props) {
  const { Option } = Select;


  return (
    <Form.Item name={props.name} rules={[{ required: true }]} label={props.label} initialValue={props.defaultValue || '0'} >
      <Select
        defaultValue={props.defaultValue || '0'}
        className="mr-2"
        style={{ minWidth: 200 }}
      >
        <Option value="0" disabled>-- Select Time --</Option>
        <Option value="5:00 am">5:00 am</Option>
        <Option value="5:30 am">5:30 am</Option>
        <Option value="6:00 am">6:00 am</Option>
        <Option value="6:30 am">6:30 am</Option>
        <Option value="7:00 am">7:00 am</Option>
        <Option value="7:30 am">7:30 am</Option>
        <Option value="8:00 am">8:00 am</Option>
        <Option value="8:30 am">8:30 am</Option>
        <Option value="9:00 am">9:00 am</Option>
        <Option value="9:03 am">9:30 am</Option>
        <Option value="10:00 am">10:00 am</Option>
        <Option value="10:30 am">10:30 am</Option>
        <Option value="11:00 am">11:00 am</Option>
        <Option value="11:30 am">11:30 am</Option>
        <Option value="12:00 pm">12:00 pm</Option>
        <Option value="12300 pm">12:30 pm</Option>
        <Option value="1:00 pm">1:00 pm</Option>
        <Option value="1:30 pm">1:30 pm</Option>
        <Option value="2:00 pm">2:00 pm</Option>
        <Option value="2:30 pm">2:30 pm</Option>
        <Option value="3:00 pm">3:00 pm</Option>
        <Option value="3:30 pm">3:30 pm</Option>
        <Option value="4:00 pm">4:00 pm</Option>
        <Option value="4:30 pm">4:30 pm</Option>
        <Option value="5:00 pm">5:00 pm</Option>
        <Option value="5:30 pm">5:30 pm</Option>
        <Option value="6:00 pm">6:00 pm</Option>
        <Option value="6:30 pm">6:30 pm</Option>
        <Option value="7:00 pm">7:00 pm</Option>
        <Option value="7:30 pm">7:30 pm</Option>
        <Option value="8:00 pm">8:00 pm</Option>
        <Option value="8:30 pm">8:30 pm</Option>
        <Option value="9:00 pm">9:00 pm</Option>
        <Option value="9:03 pm">9:30 pm</Option>
        <Option value="10:00 pm">10:00 pm</Option>
        <Option value="10:30 pm">10:30 pm</Option>
        <Option value="11:00 pm">11:00 pm</Option>
      </Select>
    </Form.Item>

  )
}

export default TimeDropDown