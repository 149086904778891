import { Input, Modal, Form, Select, Button } from 'antd'
import React, { useState } from 'react'
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    VideoCameraOutlined,
    UploadOutlined,
    FilterOutlined
} from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { searchLoc } from '../redux/actions/siteActions';




const { Search } = Input;
const {Option} = Select;

function FilterLoc() {
    const dispatch = useDispatch()

    return (
        <div className="flex">

            <Search placeholder="Filter by location" onSearch={(value)=>{dispatch(searchLoc(value))}} />
        </div>
    )
}

export default FilterLoc
