import React from 'react'
import { Row, Col, Form, Input, Button, message } from 'antd'
import { useDispatch } from 'react-redux';
import { registerUser } from '../redux/actions/userActions';
import { Link } from 'react-router-dom';





function Register() {
    const dispatch = useDispatch()

    function register(values) {

        if (values.password !== values.confirmpassword) {
            message.error('password and confirm password do not match');
        } else {
            console.log(values)
            dispatch(registerUser(values))
        }

    }

    return (
        <div className="login">
            <Row justify='center' >
                <Col lg={10} sm={24} className="bs p-5">

                    <h1>Register</h1>
                    <hr />
                    <Form layout="vertical" onFinish={register}>
                        <Form.Item label="Username" name="username" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>

                        <Form.Item label="Password" name="password" rules={[{ required: true }]}>
                            <Input.Password />
                        </Form.Item>

                        <Form.Item label="Confirm password" name="confirmpassword" rules={[{ required: true }]}>
                            <Input.Password />
                        </Form.Item>

                        <Button htmlType='submit'>Register</Button><br/>
                        <Link to='/login'>Already registered? Click here to login.</Link>

                    </Form>

                </Col>
            </Row>

        </div>
    )
}

export default Register
