import { message } from "antd";
import axios from "axios";

export const postEmp = (values) => async dispatch => {

    // values.postedBy = JSON.parse(localStorage.getItem('user'))._id

    dispatch({ type: 'LOADING', payload: true })
    try {
        const response = await axios.post('/api/auth/sign-up', values)
        dispatch({ type: 'LOADING', payload: false })
        message.success('Employee Signed up successfully')

        setTimeout(() => {
            window.location.href = '/postedScs'
        }, 1000);

    } catch (error) {
        console.log(error)
        dispatch({ type: 'LOADING', payload: false })
    }
};

