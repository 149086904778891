// import { Input, Modal, Form, Select, Button } from 'antd'
import { useState } from 'react';
import { Form, Input } from 'antd'
import axios from 'axios';

function FileUpload(props) {

    const onSubmit = (e) => {
        // e.preventDefault();

        // const data = new FormData();

        // data.append('file', file);

        // axios.post('http://localhost:6001/file-upload', data)
        //     .then((e) => {
        //         console.log('Success')
        //     })
        //     .catch((e) => {
        //         console.error("Error", e)
        //     })
    }


    return (
        // <form className="flex justify-content-between" method="post" action="#" id="#" onSubmit={onSubmit}>
        // <input type="file"
        //     onChange={props.onInputChange}
        //     className="form-control"
        //     style={{ width: 300 }} />
        // <button>Submit</button>
        // </form>
        <Form.Item name='doc' rules={[{ required: true }]} label='Document'>
            <Input type="file" onChange={props.onInputChange} />
        </Form.Item>

    )
}

export default FileUpload
