import { message } from "antd";
import axios from "axios";


export const getAllWorkers = ()=>async dispatch=>{

    dispatch({type : 'LOADING', payload : true})
    try {
        const response = await axios.get('/api/workers/getallworkers')
        dispatch({type: 'GET_ALL_WORKERS', payload: response.data})
        dispatch({type : 'LOADING', payload : false})
    } catch (error) {
        console.log(error)
        dispatch({type : 'LOADING', payload : false})
    }
};

export const postWorker =(values)=>async dispatch=>{

    values.postedBy = JSON.parse(localStorage.getItem('user'))._id

    dispatch({type : 'LOADING', payload : true})
    try {
        const response = await axios.post('/api/workers/postWorker', values)
        dispatch({type : 'LOADING', payload : false})
        message.success('Worker posted successfully')

        setTimeout(() => {
            window.location.href='/posted'
        },1000);

    } catch (error) {
        console.log(error)
        dispatch({type : 'LOADING', payload : false})
    }
};

export const editWorker =(values)=>async dispatch=>{

    dispatch({type : 'LOADING', payload : true})
    try {
        const response = await axios.post("/api/workers/editworker", values)
        dispatch({type : 'LOADING', payload : false})
        message.success('Worker edited successfully')

        setTimeout(() => {
            window.location.href='/'
        },1000);

    } catch (error) {
        console.log(error)
        dispatch({type : 'LOADING', payload : false})
    }
};

export const applyWorker =(worker)=>async dispatch=>{

    const user = JSON.parse(localStorage.getItem("user"))

    dispatch({type : 'LOADING', payload : true})
    try {
        const response = await axios.post("/api/workers/applyworker", {worker, user})
        dispatch({type : 'LOADING', payload : false})
        message.success('Worker applied successfully')

        setTimeout(() => {
            window.location.href='/'
        },1000);

    } catch (error) {
        console.log(error)
        dispatch({type : 'LOADING', payload : false})
    }
};

export const searchWorkers =(searchKey)=>async dispatch=>{

    dispatch({type : 'LOADING', payload : true})
    try {
        const response = await axios.get('/api/workers/getallworkers')

        const workers = response.data;
        const filteredWorkers = workers.filter(worker=>worker.title.toLowerCase().includes(searchKey.toLowerCase()))

       

        dispatch({type: 'GET_ALL_WORKERS', payload: filteredWorkers});

        dispatch({type : 'LOADING', payload : false});
    } catch (error) {
        console.log(error);
        dispatch({type : 'LOADING', payload : false});
    }
};

export const searchLoc =(searchKey)=>async dispatch=>{

    dispatch({type : 'LOADING', payload : true})
    try {
        const response = await axios.get('/api/workers/getallworkers')

        const workers = response.data;
        const filteredWorkers = workers.filter(worker=>worker.location.toLowerCase().includes(searchKey.toLowerCase()))

       

        dispatch({type: 'GET_ALL_WORKERS', payload: filteredWorkers});

        dispatch({type : 'LOADING', payload : false});
    } catch (error) {
        console.log(error);
        dispatch({type : 'LOADING', payload : false});
    }
};






