const initialState ={
    scs: []
}

export const scsReducer=(state=initialState, action) => {
    
    switch (action.type) {
        case 'GET_ALL_SCS' : return{
            ...state,
            scs : action.payload
        }
        default : return state
    }
}