import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import DefaultLayout from '../components/DefaultLayout'
import { Table, Modal, Button } from 'antd'
import moment from 'moment'
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    VideoCameraOutlined,
    UploadOutlined,
    EditOutlined,
    OrderedListOutlined
} from '@ant-design/icons';
import { useHistory, Link } from 'react-router-dom'


function PostedSites() {
    const allsites = useSelector(state => state.sitesReducer).sites;
    const allworkers = useSelector(state => state.workersReducer).workers;

    const allusers = useSelector(state => state.usersReducer).users;
    const userid = JSON.parse(localStorage.getItem('user'))._id;
    const userPostedSites = allsites.filter(site => site.postedBy == userid);
    const history = useHistory()
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedSite, setSelectedSite] = useState();



    const columns = [
        {
            title: 'Site Name',
            dataIndex: "siteName",
        },
        {
            title: 'Site Address',
            dataIndex: "address",
        },
        // {
        //     title: 'Posten On',
        //     dataIndex: "postedOn",
        // },

        {
            title: 'Total Employees',
            dataIndex: "appliedCanditates",
        },
        // {
        //     title: 'Employee assigned',
        //     dataIndex: "appliedCanditatesN",
        // },
        {
            title: 'Actions',
            render: (text, data) => {
                return <div className="flex">
                    <EditOutlined onClick={() => { history.push(`/editsite/${data.id}`) }} />
                    {/* <OrderedListOutlined onClick={() => { showModal(site) }} /> */}
                </div>
            },
        },
    ];

    const dataSource = []

    for (var site of userPostedSites) {
        var obj = {
            id: site['_id'],
            siteName: site.siteName,
            address: site.address,
            // postedOn: moment(site.createdAt).format('dddd, MMMM Do YYYY, h:mm:ss a'),
            appliedCanditates: site.appliedCandidates.length,
            appliedCanditatesN: site.appliedCandidates
        }
        dataSource.push(obj);

    }



    const showModal = (site) => {
        setIsModalVisible(true);
        setSelectedSite(site)
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };



    function CandidatesList() {
        const candidatesColumns = [
            {
                title: 'Candidate Id',
                dataIndex: "candidateId",
                render: (text, data) => {
                    return <Link to={`/users/${data.candidateId}`}>{data.candidateId}</Link>
                }
            },
            {
                title: 'Full Name',
                dataIndex: 'fullName'
            },
            {
                title: 'Applied Date',
                dataIndex: 'appliedDate'
            }
        ];

        var candidatesDatasource = []

        for (var candidate of selectedSite.appliedCandidates) {

            var user = allusers.find(user => user._id == candidate.userid)

            var obj = {
                candidateId: user._id,
                fullName: user.firstname + " " + user.lastname,
                appliedDate: candidate.appliedDate

            }

            candidatesDatasource.push(obj);
        }

        return <Table columns={candidatesColumns} dataSource={candidatesDatasource} />
    }

    return (
        <div>
            <DefaultLayout>
                <h3>Projects</h3>

                <div className="d-flex justify-content-end">
                    <Link to='/postsite'>Add Project</Link>
                </div>


                <Table columns={columns} dataSource={dataSource} />

                <Modal title="Applied Candidates List"
                    visible={isModalVisible}
                    closable={false}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    width={800}>

                    <CandidatesList />

                </Modal>
            </DefaultLayout>
        </div>
    )
}

export default PostedSites
