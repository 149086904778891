import { Input, DatePicker, Select, Button } from 'antd'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

const { Option } = Select;

function Filter(props) {
    const dispatch = useDispatch()
    const allworkers = useSelector(state => state.workersReducer).workers;
    const allsites = useSelector(state => state.sitesReducer).sites;

    const dateFormat = 'dddd, MMMM Do YYYY';
    const defaultDate = moment(new Date(), dateFormat);

    const [params, setParams] = useState({ date: defaultDate, worksite: '0', employee: '0' });

    function onChange(value, type) {
        setParams({
            date: type == 'date' ? value : params.date,
            worksite: type == 'worksite' ? value : params.worksite,
            employee: type == 'employee' ? value : params.employee,
        });
    }

    const onReset = () => {
        setParams({ date: defaultDate, worksite: '0', employee: '0' });
        props.onReset();
    }

    return (
        <div className="d-flex p-3">
            <DatePicker
                value={params.date}
                onChange={(date) => onChange(date, 'date')}
                format={dateFormat}
                defaultValue={defaultDate}
                style={{ width: 380 }}
                className="mr-2"
            />

            <Select
                defaultValue={params.worksite}
                className="mr-2"
                onSelect={(value) => onChange(value, 'worksite')}
                style={{ minWidth: 200 }}
                value={params.worksite}
            >
                <Option value="0" disabled>-- Select Worksite --</Option>
                {allsites.map(sites => {
                    return <Option value={sites.siteName} >{sites.siteName}</Option>
                })}
            </Select>


            <Select
                defaultValue={params.employee}
                className="mr-2"
                onSelect={(value) => onChange(value, 'employee')}
                style={{ minWidth: 200 }}
                value={params.employee}
            >
                <Option value="0" disabled>-- Select Employee --</Option>
                {allworkers.map(worker => {
                    return <Option value={worker.fname} >{worker.fname}</Option>
                })}
            </Select>

            <Button onClick={() => props.onFilter(params)} disabled={!params.date} className="mr-2">Filter</Button>
            <Button onClick={onReset}>Reset</Button>
        </div>
    )
}

export default Filter
