import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import DefaultLayout from '../components/DefaultLayout'
import { Row, Col, Form, Tabs, Input, Button, Select, Checkbox } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { updateUser } from '../redux/actions/userActions'
import { editSite, postSite } from '../redux/actions/siteActions'

const { TextArea } = Input;

const { TabPane } = Tabs;

const { Option } = Select;

function EditSite({ match }) {

    const { sites } = useSelector(state => state.sitesReducer)

    const site = sites.find(site => site._id == match.params.id)

    const [siteInfo, setSiteInfo] = useState({})
    const [activeTab, setActiveTab] = useState("0")
    const [checkedList, setCheckedList] = React.useState();

    const dispatch = useDispatch()
    const allworkers = useSelector(state => state.workersReducer).workers;
    const history = useHistory()

    const CheckboxGroup = Checkbox.Group;

    const plainOptions = allworkers.map(x => x.fname);

    useEffect(() => {
        if (site && site.appliedCandidates.length > 0) {
            setCheckedList(site.appliedCandidates);
        }
    }, [site])


    const onChange = list => {
        setCheckedList(list);
    };

    function onFinalFormFinish(values) {
        const finalObj = { ...siteInfo, ...values };

        finalObj._id = match.params.id
        console.log(finalObj)
        dispatch(editSite(finalObj))
    }


    return (
        <div>
            <DefaultLayout>
                <Tabs defaultActiveKey='0' activeKey={activeTab}>
                    <TabPane tab='Edit Site' key='0'>
                        <Form onFinish={onFinalFormFinish} onReset={() => { history.push('/posted') }} initialValues={site} >
                        <Row>
                                <Col lg={8} sm={24}>
                                    <Form.Item name='siteName' rules={[{ required: true }]} label='Site Name' >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={8} sm={24}>
                                    <Form.Item name='address' rules={[{ required: true }]} label='Site Address'>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col lg={8} sm={24}>
                                    <Form.Item name='prno' rules={[{ required: true }]} label='Project Number:'>
                                        <Input type='number' />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row>
                                <Col lg={8} sm={24}>
                                    <div>
                                        <Form.Item name='appliedCandidates' label='Select Employees'>
                                            <CheckboxGroup name='appliedCandidates' options={plainOptions} value={checkedList} onChange={onChange} />
                                        </Form.Item>
                                    </div>
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col lg={8} sm={24}>
                                    <Form.Item name='prtype' rules={[{ required: true }]} label='Project Type: '>
                                        <Select>
                                            <Option value='Fibre'>Fibre</Option>
                                            <Option value='Cabling'>Cabling</Option>
                                            <Option value='Hardware'>Hardware</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col lg={8} sm={24}>
                                    <Form.Item name='status' rules={[{ required: true }]} label='Status: '>
                                        <Select>
                                            <Option value='Active'>Active</Option>
                                            <Option value='On Hold'>On Hold</Option>
                                            <Option value='Completed'>Completed</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col lg={8} sm={24}>
                                    <Form.Item name='province' rules={[{ required: true }]} label='Province: '>
                                        <Select>
                                            <Option value='ON'>ON</Option>
                                            <Option value='NL'>NL</Option>
                                            <Option value='PE'>PE</Option>
                                            <Option value='NS'>NS</Option>
                                            <Option value='NB'>NB</Option>
                                            <Option value='QC'>QC</Option>
                                            <Option value='MB'>MB</Option>
                                            <Option value='SK'>SK</Option>
                                            <Option value='AB'>AB</Option>
                                            <Option value='BC'>BC</Option>
                                            <Option value='YT'>YT</Option>
                                            <Option value='NT'>NT</Option>
                                            <Option value='NU'>NU</Option>

                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={8} sm={24}>
                                    <Form.Item name='city' rules={[{ required: true }]} label='City'>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Button htmlType='submit'>Submit</Button>
                            <Button htmlType='reset' type="default" danger className="ml-2">Cancel</Button>
                        </Form>
                    </TabPane>

                </Tabs>
            </DefaultLayout>
        </div>
    )
}

export default EditSite
