const initialState = {
  timeSheets: []
}

export const timeSheetReducer = (state = initialState, action) => {

  switch (action.type) {
    case 'GET_ALL_TIMESHEET': return {
      ...state,
      timeSheets: action.payload
    }
    default: return state
  }
}