import { message } from "antd";
import axios from "axios";


export const getAllScs = () => async dispatch => {

    dispatch({ type: 'LOADING', payload: true })
    try {
        const response = await axios.get('/api/scs/getallscs')
        dispatch({ type: 'GET_ALL_SCS', payload: response.data })
        dispatch({ type: 'LOADING', payload: false })
    } catch (error) {
        console.log(error)
        dispatch({ type: 'LOADING', payload: false })
    }
};

export const postSc = (values) => async dispatch => {

    values.postedBy = JSON.parse(localStorage.getItem('user'))._id

    dispatch({ type: 'LOADING', payload: true })
    try {
        const response = await axios.post('/api/scs/postSc', values)
        dispatch({ type: 'LOADING', payload: false })
        message.success('Job Scheduled successfully')

        setTimeout(() => {
            window.location.href = '/postedScs'
        }, 1000);

    } catch (error) {
        console.log(error)
        dispatch({ type: 'LOADING', payload: false })
    }
};

export const editSc = (values) => async dispatch => {

    dispatch({ type: 'LOADING', payload: true })
    try {
        const response = await axios.post("/api/scs/editsc", values)
        dispatch({ type: 'LOADING', payload: false })
        message.success('Scheduled Job edited successfully')

        setTimeout(() => {
            window.location.href = '/postedScs'
        }, 1000);

    } catch (error) {
        console.log(error)
        dispatch({ type: 'LOADING', payload: false })
    }
};

// export const applySite =(site)=>async dispatch=>{

//     const user = JSON.parse(localStorage.getItem("user"))

//     dispatch({type : 'LOADING', payload : true})
//     try {
//         const response = await axios.post("/api/sites/applysite", {site, user})
//         dispatch({type : 'LOADING', payload : false})
//         message.success('Site applied successfully')

//         setTimeout(() => {
//             window.location.href='/'
//         },1000);

//     } catch (error) {
//         console.log(error)
//         dispatch({type : 'LOADING', payload : false})
//     }
// };

// export const searchSites =(searchKey)=>async dispatch=>{

//     dispatch({type : 'LOADING', payload : true})
//     try {
//         const response = await axios.get('/api/sites/getallsites')

//         const sites = response.data;
//         const filteredSites = sites.filter(site=>site.title.toLowerCase().includes(searchKey.toLowerCase()))



//         dispatch({type: 'GET_ALL_JOBS', payload: filteredSites});

//         dispatch({type : 'LOADING', payload : false});
//     } catch (error) {
//         console.log(error);
//         dispatch({type : 'LOADING', payload : false});
//     }
// };

// export const searchLoc =(searchKey)=>async dispatch=>{

//     dispatch({type : 'LOADING', payload : true})
//     try {
//         const response = await axios.get('/api/sites/getallsites')

//         const sites = response.data;
//         const filteredSites = sites.filter(site=>site.location.toLowerCase().includes(searchKey.toLowerCase()))



//         dispatch({type: 'GET_ALL_JOBS', payload: filteredSites});

//         dispatch({type : 'LOADING', payload : false});
//     } catch (error) {
//         console.log(error);
//         dispatch({type : 'LOADING', payload : false});
//     }
// };






