import React, { useState } from 'react'
import DefaultLayout from '../components/DefaultLayout'
import { Row, Col, Form, Tabs, Input, Button, Select, Checkbox, Divider } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { editDoc } from '../redux/actions/docActions'
import FileUpload from '../components/FileUpload'
import axios from 'axios';
import { useHistory } from 'react-router-dom'

const { TabPane } = Tabs;

const { Option } = Select;


function EditDoc({ match }) {

  const [docInfo, setDocInfo] = useState({})
  const [activeTab, setActiveTab] = useState("0")
  const [file, setFile] = useState(null);
  const dispatch = useDispatch()
  const history = useHistory()

  async function onFinalFormFinish(values) {
    const data = new FormData();
    data.append('file', file);

    axios.post('http://localhost:5000/file-upload', data)
      .then((e) => {
        console.log('Success');
        const finalObj = { ...docInfo, ...values };

        finalObj._id = match.params.id
        console.log(finalObj)
        dispatch(editDoc(finalObj))
      })
      .catch((e) => {
        console.error("Error", e)
      })
  }

  const onInputChange = (e) => {
    setFile(e.target.files[0])
    console.log(e.target.files[0])
  };

  const { docs } = useSelector(state => state.docsReducer)

  const doc = docs.find(doc => doc._id == match.params.id)

  return (
    <div>
      <DefaultLayout>
        <Tabs defaultActiveKey='0' activeKey={activeTab}>
          <TabPane tab='Edit Document' key='0'>
            <Form onFinish={onFinalFormFinish} onReset={() => { history.push('/postedDocs') }} initialValues={doc}>
              <Row gutter={16}>
                <Col lg={8} sm={24}>
                  <Form.Item name='docName' rules={[{ required: true }]} label='Document Name'>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col lg={8} sm={24}>
                  <Form.Item name='docType' rules={[{ required: true }]} label='Document Type: '>
                    <Select>
                      <Option value='Manual'>Manual</Option>
                      <Option value='Safety'>Safety</Option>
                      <Option value='Checklist'>Checklist</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col lg={8} sm={24}>
                  <FileUpload onInputChange={onInputChange} />
                </Col>
              </Row>

              <Button htmlType='submit' className="my-2">Submit</Button>
              <Button htmlType='reset' type="default" danger className="ml-2">Cancel</Button>
            </Form>

          </TabPane>

        </Tabs>
      </DefaultLayout>
    </div>
  )
}

export default EditDoc
