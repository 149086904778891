import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import DefaultLayout from '../components/DefaultLayout'
import { Row, Col, Form, Tabs, Input, Button, Select, Checkbox, DatePicker } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { editSc } from '../redux/actions/scActions'
import moment from 'moment';
import TimeDropDown from '../components/TimeDropDown'


const { TabPane } = Tabs;

const { Option } = Select;

function EditSc({ match }) {

    const { scs } = useSelector(state => state.scsReducer)

    const sc = scs.find(sc => sc._id == match.params.id)

    const [scInfo, setScInfo] = useState({})
    const [activeTab, setActiveTab] = useState("0")

    const dispatch = useDispatch()
    const allworkers = useSelector(state => state.workersReducer).workers;
    const allsites = useSelector(state => state.sitesReducer).sites;
    const history = useHistory()

    function onFinalFormFinish(values) {
        const finalObj = { ...scInfo, ...values };

        finalObj._id = match.params.id
        console.log(finalObj)
        dispatch(editSc(finalObj))
    }

    return (
        <div>
            <DefaultLayout>
                <Tabs defaultActiveKey='0' activeKey={activeTab}>
                    <TabPane tab='Schedule Job' key='0'>
                        <Form onFinish={onFinalFormFinish} onReset={() => { history.push('/posted') }} >
                            <Row>
                                <Col lg={8} sm={24}>
                                    <div>
                                        <Form.Item name='appliedCandidate' label='Select Employees' valuePropName="option" initialValue={sc.appliedCandidate}>
                                            <Select
                                                defaultValue={sc.appliedCandidate}
                                                className="mr-2"
                                                style={{ minWidth: 200 }}

                                            >
                                                <Option value="0" disabled>-- Select Employee --</Option>
                                                {allworkers.map(worker => {
                                                    return <Option value={worker.fname} key={worker.fname} >{worker.fname}</Option>
                                                })}
                                            </Select>
                                        </Form.Item>

                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={8} sm={24}>
                                    <Form.Item name='date' rules={[{ required: true }]} label='Date' initialValue={moment(new Date(sc.date), 'dddd, MMMM Do YYYY')}>
                                        <DatePicker
                                            format='dddd, MMMM Do YYYY'
                                            defaultValue={moment(new Date(sc.date), 'dddd, MMMM Do YYYY')}
                                            style={{ width: 380 }}
                                            className="mr-2"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={8} sm={24}>
                                    {/* <Form.Item name='loc' rules={[{ required: true }]} label='Location' initialValue={sc.loc} >
                                        <Input />
                                    </Form.Item> */}
                                    <Form.Item name='worksite' rules={[{ required: true }]} label='Worksite' initialValue={sc.worksite} >
                                        <Select
                                            defaultValue={'0'}
                                            className="mr-2"
                                            style={{ minWidth: 200 }}
                                        >
                                            <Option value="0" disabled>-- Select Worksite --</Option>
                                            {allsites.map(site => {
                                                return <Option value={site.siteName} key={site['_id']} >{site.siteName}</Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={8} sm={24}>
                                    <TimeDropDown name='stime' label='Start Time' defaultValue={sc.stime} />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={8} sm={24}>
                                    <TimeDropDown name='etime' label='End Time' defaultValue={sc.etime} />
                                </Col>
                            </Row>


                            <Button htmlType='submit'>Submit</Button>
                            <Button htmlType='reset' type="default" danger className="ml-2">Cancel</Button>
                        </Form>
                    </TabPane>

                </Tabs>
            </DefaultLayout>
        </div>
    )
}

export default EditSc
