import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import DefaultLayout from '../components/DefaultLayout'
import { Table, Modal } from 'antd'
import moment from 'moment'
import { EditOutlined } from '@ant-design/icons';
import { useHistory, Link } from 'react-router-dom'
import Filter from '../components/Filter';


function PostedTimeSheet() {
  const allTimeSheets = useSelector(state => state.timeSheetReducer).timeSheets;

  const allusers = useSelector(state => state.usersReducer).users;
  const userid = JSON.parse(localStorage.getItem('user'))._id;
  const userPostedTimeSheets = allTimeSheets.filter(ts => ts.postedBy == userid);
  const history = useHistory()
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedSite, setSelectedSite] = useState();
  const [dataSource, setDataSource] = useState();
  const [filteredDataSource, setFilteredDataSource] = useState();

  const columns = [
    {
      title: 'Employee',
      dataIndex: "appliedCandidate",
    },
    {
      title: 'Date',
      dataIndex: "date",
    },
    {
      title: 'Worksite',
      dataIndex: "worksite",
    },
    {
      title: 'Start Time',
      dataIndex: "stime",
    },
    {
      title: 'End Time',
      dataIndex: "etime",
    },
    {
      title: 'Actions',
      render: (text, data) => {
        return <div className="flex">
          <EditOutlined onClick={() => { history.push(`/edittimesheet/${data.id}`) }} />
        </div>
      },
    },
  ];

  useEffect(() => {
    if (allTimeSheets.length > 0) {
      const forms = userPostedTimeSheets.map(ts => {
        return {
          id: ts['_id'],
          appliedCandidate: ts.appliedCandidate,
          date: moment(new Date(ts.date)).format('dddd, MMMM Do YYYY'),
          worksite: ts.worksite,
          stime: ts.stime,
          etime: ts.etime
        };
      });
      setDataSource(forms);
      setFilteredDataSource(forms);
    }
  }, [allTimeSheets])

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  function CandidatesList() {
    const candidatesColumns = [
      {
        title: 'Candidate Id',
        dataIndex: "candidateId",
        render: (text, data) => {
          return <Link to={`/users/${data.candidateId}`}>{data.candidateId}</Link>
        }
      },
      {
        title: 'Full Name',
        dataIndex: 'fullName'
      },
      {
        title: 'Applied Date',
        dataIndex: 'appliedDate'
      }
    ];

    var candidatesDatasource = []

    for (var candidate of selectedSite.appliedCandidate) {

      var user = allusers.find(user => user._id == candidate.userid)

      var obj = {
        candidateId: user._id,
        fullName: user.firstname + " " + user.lastname,
        appliedDate: candidate.appliedDate

      }

      candidatesDatasource.push(obj);
    }

    return <Table columns={candidatesColumns} dataSource={candidatesDatasource} />
  }

  function onFilter(params) {
    const hasDate = params.date ?? false;
    if (hasDate) {
      const hasEmployee = params.employee !== '0';
      const hasWorksite = params.worksite !== '0';

      const filterDate = params.date?.format('dddd, MMMM Do YYYY');

      setFilteredDataSource(dataSource.filter(x => (
        filterDate === x.date &&
        (hasWorksite ? x.worksite === params.worksite : true) &&
        (hasEmployee ? x.appliedCandidate === params.employee : true)
      )
      ));
    }
  }

  return (
    <div>
      <DefaultLayout>
        <h3>Time Sheets</h3>

        <div className="d-flex justify-content-end">
          <Link to='postTimeSheet'>Add Time Sheet</Link>
        </div>

        <Filter onFilter={onFilter} onReset={() => setFilteredDataSource(dataSource)} hasLoc />
        <Table columns={columns} dataSource={filteredDataSource} />

        <Modal title="Applied Candidates List"
          visible={isModalVisible}
          closable={false}
          onOk={handleOk}
          onCancel={handleCancel}
          width={800}>

          <CandidatesList />

        </Modal>
      </DefaultLayout>
    </div>
  )
}

export default PostedTimeSheet;