import React, { useState } from 'react'
import DefaultLayout from '../components/DefaultLayout'
import { Row, Col, Form, Tabs, Input, Button, Select, Checkbox, Divider } from 'antd'
import { useDispatch } from 'react-redux'
import { updateUser } from '../redux/actions/userActions'
import { postWorker } from '../redux/actions/workerActions'
import { useHistory } from 'react-router-dom'


const { TextArea } = Input;

const { TabPane } = Tabs;

const { Option } = Select;

function PostWorker() {

    const [workerInfo, setWorkerInfo] = useState({})
    const [activeTab, setActiveTab] = useState("0")
    const dispatch = useDispatch()
    const history = useHistory()

    const CheckboxGroup = Checkbox.Group;

    const plainOptions = ['WorkerOne', 'WorkerTwo', 'WorkerThree'];
    const defaultCheckedList = [];

    const [checkedList, setCheckedList] = React.useState(defaultCheckedList);
    const [indeterminate, setIndeterminate] = React.useState(true);
    const [checkAll, setCheckAll] = React.useState(false);

    const onChange = list => {
        setCheckedList(list);
        setIndeterminate(!!list.length && list.length < plainOptions.length);
        setCheckAll(list.length === plainOptions.length);
    };

    const onCheckAllChange = e => {
        setCheckedList(e.target.checked ? plainOptions : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    };




    // function onFirstFormFinish(values){
    //     s
    //     setActiveTab("1")
    //     }
    function onFinalFormFinish(values) {
        setWorkerInfo(values)
        const finalObj = { ...workerInfo, ...values };
        console.log(finalObj)
        dispatch(postWorker(finalObj));
    }

    return (
        <div>
            <DefaultLayout>
                <Tabs defaultActiveKey='0' activeKey={activeTab}>
                    <TabPane tab='Add Employee' key='0'>
                        <Form onFinish={onFinalFormFinish} onReset={() => { history.push('/postedWorkers') }}>
                            <Row gutter={16}>
                                <Col lg={8} sm={24}>
                                    <Form.Item name='fname' rules={[{ required: true }]} label='First Name'>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col lg={8} sm={24}>
                                    <Form.Item name='lname' rules={[{ required: true }]} label='Last Name'>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col lg={8} sm={24}>
                                    <Form.Item name='lead' rules={[{ required: true }]} label='Is Lead: '>
                                        <Select>
                                            <Option value='True'>True</Option>
                                            <Option value='False'>False</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col lg={8} sm={24}>
                                    <Form.Item name='admin' rules={[{ required: true }]} label='Is Admin: '>
                                        <Select>
                                            <Option value='True'>True</Option>
                                            <Option value='False'>False</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col lg={8} sm={24}>
                                    <Form.Item name='phone' rules={[{ required: true }]} label='Phone:'>
                                        <Input type='number' />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col lg={8} sm={24}>
                                    <Form.Item name='email' rules={[{ required: true }]} label='Email'>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col lg={8} sm={24}>
                                    <Form.Item name='password' rules={[{ required: true }]} label='Password'>
                                    <Input.Password />                                   
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Button htmlType='submit'>Submit</Button>
                            <Button htmlType='reset' type="default" danger className="ml-2">Cancel</Button>
                        </Form>
                    </TabPane>

                </Tabs>
            </DefaultLayout>
        </div>
    )
}

export default PostWorker
