import { message } from "antd";
import axios from "axios";


export const getAllTimeSheet = () => async dispatch => {
  dispatch({ type: 'LOADING', payload: true })
  try {
    const response = await axios.get('/api/timesheet/getalltimesheets')
    dispatch({ type: 'GET_ALL_TIMESHEET', payload: response.data })
    dispatch({ type: 'LOADING', payload: false })
  } catch (error) {
    console.log(error)
    dispatch({ type: 'LOADING', payload: false })
  }
};

export const postTimeSheet = (values) => async dispatch => {

  values.postedBy = JSON.parse(localStorage.getItem('user'))._id

  dispatch({ type: 'LOADING', payload: true })
  try {
    const response = await axios.post('/api/timesheet/postTimeSheet', values)
    dispatch({ type: 'LOADING', payload: false })
    message.success('Expense added successfully')

    setTimeout(() => {
      window.location.href = '/postedTimeSheet'
    }, 1000);

  } catch (error) {
    console.log(error)
    dispatch({ type: 'LOADING', payload: false })
  }
};

export const editTimeSheet = (values) => async dispatch => {

  dispatch({ type: 'LOADING', payload: true })
  try {
    const response = await axios.post("/api/timesheet/editTimeSheet", values)
    dispatch({ type: 'LOADING', payload: false })
    message.success('Expense edited successfully')

    setTimeout(() => {
      window.location.href = '/postedTimeSheet'
    }, 1000);

  } catch (error) {
    console.log(error)
    dispatch({ type: 'LOADING', payload: false })
  }
};
