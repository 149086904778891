import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import DefaultLayout from '../components/DefaultLayout'
import { Table, Modal, Button } from 'antd'
import moment from 'moment'
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    VideoCameraOutlined,
    UploadOutlined,
    EditOutlined,
    DownloadOutlined
} from '@ant-design/icons';
import { useHistory, Link } from 'react-router-dom'


function PostedDocs() {
    const alldocs = useSelector(state => state.docsReducer).docs;
    const allusers = useSelector(state => state.usersReducer).users;
    const userid = JSON.parse(localStorage.getItem('user'))._id;
    const userPostedDocs = alldocs.filter(doc => doc.postedBy == userid);
    const history = useHistory()
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedSite, setSelectedSite] = useState();

    const columns = [
        {
            title: 'Doc Name',
            dataIndex: "docName",
        },
        {
            title: 'Doc Type',
            dataIndex: "docType",
        },
        {
            title: 'Posten On',
            dataIndex: "postedOn",
        },
        {
            title: 'Actions',
            render: (text, data) => {
                return <div className="flex">
                    <EditOutlined onClick={() => { history.push(`/editdoc/${data.id}`) }} />
                    <DownloadOutlined className='ml-3' onClick={() => {
                        window.open(data.downloadLink, "_blank")
                    }} />
                </div>
            },
        },
    ];

    const dataSource = []

    for (var doc of userPostedDocs) {
        var obj = {
            id: doc['_id'],
            docName: doc.docName,
            docType: doc.docType,
            postedOn: moment(doc.createdAt).format('dddd, MMMM Do YYYY, h:mm:ss a'),
            downloadLink: doc.downloadLink,
        }
        dataSource.push(obj);

    }

    const showModal = (site) => {
        setIsModalVisible(true);
        setSelectedSite(site)
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };



    function CandidatesList() {
        const candidatesColumns = [
            {
                title: 'Candidate Id',
                dataIndex: "candidateId",
                render: (text, data) => {
                    return <Link to={`/users/${data.candidateId}`}>{data.candidateId}</Link>
                }
            },
            {
                title: 'Full Name',
                dataIndex: 'fullName'
            },
            {
                title: 'Applied Date',
                dataIndex: 'appliedDate'
            }
        ];

        var candidatesDatasource = []

        for (var candidate of selectedSite.appliedCandidates) {

            var user = allusers.find(user => user._id == candidate.userid)

            var obj = {
                candidateId: user._id,
                fullName: user.firstname + " " + user.lastname,
                appliedDate: candidate.appliedDate

            }

            candidatesDatasource.push(obj);
        }

        return <Table columns={candidatesColumns} dataSource={candidatesDatasource} />
    }

    // console.log(userPostedSites)

    return (
        <div>
            <DefaultLayout>
                <h3>Added Safety Documents</h3>

                <div className="d-flex justify-content-end">
                    <Link to='/postdoc'>Add Safety Document</Link>
                </div>



                <Table columns={columns} dataSource={dataSource} />

                <Modal title="Applied Candidates List"
                    visible={isModalVisible}
                    closable={false}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    width={800}>

                    <CandidatesList />

                </Modal>
            </DefaultLayout>
        </div>
    )
}

export default PostedDocs
