import React from 'react';
import { Image, Layout, Menu, Breadcrumb } from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import Filter from './Filter';
import FilterLoc from './FilterLoc';




const { Header, Content, Footer } = Layout;

class DefaultLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
    };
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  logout = () => {
    localStorage.removeItem('user')
    window.location.reload();
  }


  render() {
    const user = JSON.parse(localStorage.getItem('user'))
    return (
      <Layout className="layout">
        <Header>
          {/* <div className="logo"><Image src="https://www.u-r-hired.com/wp-content/uploads/2021/08/logo.png" /></div> */}

          <Menu theme="light" mode="horizontal" defaultSelectedKeys={['2']}>
            {/* <Menu.Item key="/" >
     <h3>SALUS</h3>
            </Menu.Item>
      */}

            {/* <Menu.Item key="/postsite" >
              <Link to='/postsite'>Add Sites</Link>
            </Menu.Item> */}

            {/* 
            <Menu.Item key="/register" >
              <Link to='/register'>Add employees</Link>
            </Menu.Item> */}

            {/* <Menu.Item key="/post" >
              <Link to='/postworker'>Add Worker</Link>
            </Menu.Item> */}

            {/* 
            <Menu.Item key="/postsite" >
              <Link to='/postsite'>Post Sites</Link>
            </Menu.Item> */}

            <Menu.Item key="/posted" >
              <Link to='/posted'>Projects</Link>
            </Menu.Item>
            {/* <Menu.Item key="/posted" >
              <Link to='/postedWorkers'>Posted Workers</Link>
            </Menu.Item> */}

            <Menu.Item key="/postedWorkers" >
              <Link to='/postedWorkers'>Employees</Link>
            </Menu.Item>

            <Menu.Item key="/postedForms" >
              <Link to='/postedForms'>Safety Forms</Link>
            </Menu.Item>

            <Menu.Item key="/postedDocs" >
              <Link to='/postedDocs'>Safety Documents</Link>
            </Menu.Item>

            <Menu.Item key="/postedScs" >
              <Link to='/postedScs'>Job Scheduling</Link>
            </Menu.Item>

            <Menu.Item key="/postedExpenses" >
              <Link to='/postedExpenses'>Expenses</Link>
            </Menu.Item>

            <Menu.Item key="/postedTimeSheet" >
              <Link to='/postedTimeSheet'>Time Sheet</Link>
            </Menu.Item>

            <Menu.Item key="/logout" >
              <Link onClick={this.logout}>Logout</Link>
            </Menu.Item>

            {/* <Menu.Item className ="searchIcon" >
            <Filter/>
            </Menu.Item>
           */}

          </Menu>
        </Header>
        <Content style={{ padding: '0 50px' }}>
          <Breadcrumb style={{ margin: '16px 0' }}>
            {/* <Breadcrumb.Item>USERNAME : {user.username}</Breadcrumb.Item> */}
            <h3>{user.username}</h3>
          </Breadcrumb>
          <div className="site-layout-content">{this.props.children}</div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>Copyright © 2021. All Rights Reserved. </Footer>
      </Layout>
    );
  }
}

export default DefaultLayout
