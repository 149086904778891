import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DefaultLayout from '../components/DefaultLayout'
import { Upload, message, Button } from 'antd';
import moment from 'moment'
import { Link } from 'react-router-dom';
import { applySite } from '../redux/actions/siteActions'
import { UploadOutlined } from '@ant-design/icons';
import axios from "axios";
import  FileUpload  from '../components/FileUpload'


function SiteInfo({ match }) {

    const { sites } = useSelector(state => state.sitesReducer)

    const site = sites.find(site => site._id == match.params.id)

    const userid = JSON.parse(localStorage.getItem('user'))._id

    const appliedCandidates = site.appliedCandidates

    const alreadyApplied = appliedCandidates.find(candidate => candidate.userid == userid)


    const dispatch = useDispatch()


    function applyNow() {
        dispatch(applySite(site))


    }

    return (
        <div>
            <DefaultLayout>

                {site && (<div>
                    <h1><b>Title :</b> {site.title}</h1>
                    <p><b>Hiring Organization :</b> {site.hiringOrg}</p>
                    <p><b>Employment Type :</b> {site.empType}</p>
                    <p><b>Location :</b> {site.location}</p>
                    <hr />

                    <p><b>Major Duties and Responsibilities: :</b> {site.fullDescription}</p>
                    <p><b>Minor Duties and Responsibilities: :</b> {site.smallDescription}</p>
                    <p><b>Minimum Qualifications and Skills: :</b> {site.skillsRequired}</p>
                    <hr />
                    <p><b>SWe are proud to offer: :</b> {site.perks}</p>
                    <p><b>Salary Range Offered :</b> {site.salaryFrom} - {site.alaryTo}</p>
                    <p><b>Additional Pay :</b> {site.addPay}</p>
                    <p><b>Benefits :</b> {site.benefits}</p>
                    <p><b>Schedule :</b> {site.schedule}</p>
                    <p><b>Remote :</b> {site.remote}</p>
                    <p><b>Notes :</b> {site.notes}</p>
                    <p><b>Total Candidates Applied :</b> {site.appliedCandidates.length}</p>
                    <p><b>Total Candidates Applied :</b> {site.appliedCandidates}</p>
                    <hr />

                    <div className="flex justify-content-between">
                        {site.postedBy == userid ? (
                            <Button>
                                <Link to={`/editsite/${site._id}`}>Edit Now</Link></Button>) : alreadyApplied ? (<p>Already applied</p>) : (<Button onClick={applyNow}>Apply Now</Button>)}

                            <FileUpload />
                        
                        <p><b>Posted on : </b>{moment(site.createdAt).format('MMM DD YYYY')}</p>
                    </div>

                </div>)}

            </DefaultLayout>
        </div>
    )
}

export default SiteInfo
