import { combineReducers } from "redux"
import { composeWithDevTools } from "redux-devtools-extension";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { sitesReducer } from "./reducers/sitesReducer";
import { loaderReducer } from "./reducers/loaderReducer";
import { usersReducer } from "./reducers/usersReducer";
import { workersReducer } from "./reducers/workersReducer";
import { formsReducer } from "./reducers/formsReducer";
import { docsReducer } from "./reducers/docsReducer";
import { scsReducer } from "./reducers/scsReducer";
import { expenseReducer } from "./reducers/expenseReducer";
import { timeSheetReducer } from "./reducers/timeSheetReducer";

const rootReducer = combineReducers({
    sitesReducer: sitesReducer,
    loaderReducer: loaderReducer,
    usersReducer: usersReducer,
    workersReducer: workersReducer,
    formsReducer: formsReducer,
    docsReducer: docsReducer,
    scsReducer: scsReducer,
    expenseReducer: expenseReducer,
    timeSheetReducer: timeSheetReducer,
})

const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(thunk))
);

export default store;