import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import DefaultLayout from '../components/DefaultLayout'
import { Table, Modal, Button } from 'antd'
import moment from 'moment'
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    VideoCameraOutlined,
    UploadOutlined,
    EditOutlined,
    OrderedListOutlined
} from '@ant-design/icons';
import { useHistory, Link } from 'react-router-dom'


function PostedWorkers() {
    const allworkers = useSelector(state => state.workersReducer).workers;

    const allusers = useSelector(state => state.usersReducer).users;
    const userid = JSON.parse(localStorage.getItem('user'))._id;
    const userPostedWorkers = allworkers.filter(worker => worker.postedBy == userid);

    const history = useHistory()
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedSite, setSelectedSite] = useState();

    const columns = [
        {
            title: 'Name',
            dataIndex: "fname",
        },
        {
            title: 'Role',
            dataIndex: "role",
        },
        {
            title: 'Phone',
            dataIndex: "phone",
        },
        {
            title: 'Email',
            dataIndex: "email",
        },
        // {
        //     title: 'Added On',
        //     dataIndex: "postedOn",
        // },
        {
            title: 'Actions',
            render: (text, data) => {
                return <div className="flex">
                    <EditOutlined onClick={() => { history.push(`/editworker/${data.id}`) }} />
                    {/* <OrderedListOutlined onClick={() => { showModal(worker) }} /> */}
                </div>
            },
        },
    ];

    const dataSource = []

    for (var worker of userPostedWorkers) {
        var obj = {
            id: worker['_id'],
            fname: worker.fname + " " + worker.lname,
            role: worker.role,
            phone: worker.phone,
            email: worker.email,
            // postedOn: moment(worker.createdAt).format('dddd, MMMM Do YYYY, h:mm:ss a'),
        }
        dataSource.push(obj);

    }



    const showModal = (site) => {
        setIsModalVisible(true);
        setSelectedSite(site)
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };



    function CandidatesList() {
        const candidatesColumns = [
            {
                title: 'Candidate Id',
                dataIndex: "candidateId",
                render: (text, data) => {
                    return <Link to={`/users/${data.candidateId}`}>{data.candidateId}</Link>
                }
            },
            {
                title: 'Full Name',
                dataIndex: 'fullName'
            },
            {
                title: 'Applied Date',
                dataIndex: 'appliedDate'
            }
        ];

        var candidatesDatasource = []

        for (var candidate of selectedSite.appliedCandidates) {

            var user = allusers.find(user => user._id == candidate.userid)

            var obj = {
                candidateId: user._id,
                fullName: user.firstname + " " + user.lastname,
                appliedDate: candidate.appliedDate

            }

            candidatesDatasource.push(obj);
        }

        return <Table columns={candidatesColumns} dataSource={candidatesDatasource} />
    }

    return (
        <div>
            <DefaultLayout>
                <h3>Added Employees</h3>

                <div className="d-flex justify-content-end">
                    <Link to='/postworker'>Add Employee</Link>
                </div>
                {/* <div className="d-flex justify-content-end">
                    <Link to='/empsignup'>Employee Signup</Link>
                </div> */}


                <Table columns={columns} dataSource={dataSource} />

                <Modal title="Applied Candidates List"
                    visible={isModalVisible}
                    closable={false}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    width={800}>

                    <CandidatesList />

                </Modal>
            </DefaultLayout>
        </div>
    )
}

export default PostedWorkers
