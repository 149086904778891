import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import DefaultLayout from '../components/DefaultLayout'
import { Table, Modal, Button } from 'antd'
import moment from 'moment'
import {
    DownloadOutlined
} from '@ant-design/icons';
import { useHistory, Link } from 'react-router-dom'
import Filter from '../components/Filter';


function PostedForms() {
    const allforms = useSelector(state => state.formsReducer).forms;
    const allusers = useSelector(state => state.usersReducer).users;


    const userid = JSON.parse(localStorage.getItem('user'))._id;
    const userPostedForms = allforms.filter(site => site.postedBy == userid);

    const history = useHistory()
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedSite, setSelectedSite] = useState();
    const [dataSource, setDataSource] = useState(userPostedForms);
    const [filteredDataSource, setFilteredDataSource] = useState(userPostedForms);

    const columns = [
        {
            title: 'Form',
            dataIndex: "form",
        },
        {
            title: 'Worksite',
            dataIndex: "worksite",
        },
        {
            title: 'Employee',
            dataIndex: "emp",
        },
        {
            title: 'Filled On',
            dataIndex: "createdAt",
        },
        {
            title: 'Actions',
            render: (text, data) => {
                return <div className="flex">
                    <DownloadOutlined className='ml-3' onClick={() => {
                        window.open(data.downloadLink, "_blank")
                    }} />
                </div>
            },
        },
    ];

    useEffect(() => {
        if (allforms.length > 0) {
            const forms = userPostedForms.map(form => {
                return {
                    form: form.form,
                    worksite: form.worksite,
                    emp: form.emp,
                    createdAt: moment(form.createdAt).format('dddd, MMMM Do YYYY, h:mm:ss a'),
                    downloadLink: form.downloadLink,
                };
            });
            setDataSource(forms);
            setFilteredDataSource(forms);
        }
    }, [allforms])



    const showModal = (site) => {
        setIsModalVisible(true);
        setSelectedSite(site)
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };



    function CandidatesList() {
        const candidatesColumns = [
            {
                title: 'Candidate Id',
                dataIndex: "candidateId",
                render: (text, data) => {
                    return <Link to={`/users/${data.candidateId}`}>{data.candidateId}</Link>
                }
            },
            {
                title: 'Full Name',
                dataIndex: 'fullName'
            },
            {
                title: 'Applied Date',
                dataIndex: 'appliedDate'
            }
        ];

        var candidatesDatasource = []

        for (var candidate of selectedSite.appliedCandidates) {

            var user = allusers.find(user => user._id == candidate.userid)

            var obj = {
                candidateId: user._id,
                fullName: user.firstname + " " + user.lastname,
                appliedDate: candidate.appliedDate

            }

            candidatesDatasource.push(obj);
        }

        return <Table columns={candidatesColumns} dataSource={candidatesDatasource} />
    }

    function onFilter(params) {
        const hasDate = params.date ?? false;
        if (hasDate) {
            const hasWorksite = params.worksite !== '0';
            const hasEmployee = params.employee !== '0';

            const filterDate = params.date?.format('MMMM Do YYYY');

            setFilteredDataSource(dataSource.filter(x => (
                filterDate === x.createdAt.split(',')[1].trim() &&
                (hasWorksite ? x.worksite === params.worksite : true) &&
                (hasEmployee ? x.emp === params.employee : true)
            )
            ));
        }
    }

    return (
        <div>
            <DefaultLayout>
                <Filter onFilter={onFilter} onReset={() => setFilteredDataSource(dataSource)} hasWorksite />
                <Table columns={columns} dataSource={filteredDataSource} />

                <Modal title="Applied Candidates List"
                    visible={isModalVisible}
                    closable={false}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    width={800}>

                    <CandidatesList />

                </Modal>
            </DefaultLayout>
        </div>
    )
}

export default PostedForms
