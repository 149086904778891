import { message } from "antd";
import axios from "axios";


export const getAllDocs = () => async dispatch => {

    dispatch({ type: 'LOADING', payload: true })
    try {
        const response = await axios.get('/api/docs/getalldocs')
        dispatch({ type: 'GET_ALL_DOCS', payload: response.data })
        dispatch({ type: 'LOADING', payload: false })
    } catch (error) {
        console.log(error)
        dispatch({ type: 'LOADING', payload: false })
    }
};

export const postDoc = (values) => async dispatch => {

    values.postedBy = JSON.parse(localStorage.getItem('user'))._id

    dispatch({ type: 'LOADING', payload: true })
    try {
        const response = await axios.post('/api/docs/postDoc', values)
        dispatch({ type: 'LOADING', payload: false })
        message.success('Doc posted successfully')

        setTimeout(() => {
            window.location.href = '/postedDocs'
        }, 1000);

    } catch (error) {
        console.log(error)
        dispatch({ type: 'LOADING', payload: false })
    }
};

export const editDoc = (values) => async dispatch => {

    dispatch({ type: 'LOADING', payload: true })
    try {
        const response = await axios.post("/api/docs/editdoc", values)
        dispatch({ type: 'LOADING', payload: false })
        message.success('Doc edited successfully')

        setTimeout(() => {
            window.location.href = '/'
        }, 1000);

    } catch (error) {
        console.log(error)
        dispatch({ type: 'LOADING', payload: false })
    }
};






