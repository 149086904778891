import { message } from "antd";
import axios from "axios";


export const getAllForms = ()=>async dispatch=>{

    dispatch({type : 'LOADING', payload : true})
    try {
        const response = await axios.get('/api/forms/getallforms')
        dispatch({type: 'GET_ALL_FORMS', payload: response.data})
        dispatch({type : 'LOADING', payload : false})
    } catch (error) {
        console.log(error)
        dispatch({type : 'LOADING', payload : false})
    }
};

// export const editWorker =(values)=>async dispatch=>{

//     dispatch({type : 'LOADING', payload : true})
//     try {
//         const response = await axios.post("/api/workers/editworker", values)
//         dispatch({type : 'LOADING', payload : false})
//         message.success('Worker edited successfully')

//         setTimeout(() => {
//             window.location.href='/'
//         },1000);

//     } catch (error) {
//         console.log(error)
//         dispatch({type : 'LOADING', payload : false})
//     }
// };


// export const searchWorkers =(searchKey)=>async dispatch=>{

//     dispatch({type : 'LOADING', payload : true})
//     try {
//         const response = await axios.get('/api/workers/getallworkers')

//         const workers = response.data;
//         const filteredWorkers = workers.filter(worker=>worker.title.toLowerCase().includes(searchKey.toLowerCase()))

       

//         dispatch({type: 'GET_ALL_WORKERS', payload: filteredWorkers});

//         dispatch({type : 'LOADING', payload : false});
//     } catch (error) {
//         console.log(error);
//         dispatch({type : 'LOADING', payload : false});
//     }
// };

// export const searchLoc =(searchKey)=>async dispatch=>{

//     dispatch({type : 'LOADING', payload : true})
//     try {
//         const response = await axios.get('/api/workers/getallworkers')

//         const workers = response.data;
//         const filteredWorkers = workers.filter(worker=>worker.location.toLowerCase().includes(searchKey.toLowerCase()))

       

//         dispatch({type: 'GET_ALL_WORKERS', payload: filteredWorkers});

//         dispatch({type : 'LOADING', payload : false});
//     } catch (error) {
//         console.log(error);
//         dispatch({type : 'LOADING', payload : false});
//     }
// };






