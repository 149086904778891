import './App.css';
import 'antd/dist/antd.css';
import SiteInfo from './pages/SiteInfo';

import { BrowserRouter, Redirect, Route } from 'react-router-dom';
import PostSite from './pages/PostSite';
import PostSc from './pages/PostSc';

import PostDoc from './pages/PostDoc';
import EmpSignup from './pages/EmpSignup';
import PostWorker from './pages/PostWorker';
import Profile from './pages/Profile';
import RingLoader from "react-spinners/RingLoader";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Login from './pages/Login';
import Register from './pages/Register';
import PostedSites from './pages/PostedSites';
import PostedScs from './pages/PostedScs';
import PostedWorkers from './pages/PostedWorkers';
import PostedForms from './pages/PostedForms';
import PostedDocs from './pages/PostedDocs';
import PostedExpenses from './pages/PostedExpenses';
import PostedTimeSheet from './pages/PostedTimeSheet';
import EditSite from './pages/EditSite';
import EditSc from './pages/EditSc';
import EditWorker from './pages/EditWorker';
import EditDoc from './pages/EditDoc';
import { getAllSites } from "./redux/actions/siteActions.js";
import { getAllScs } from "./redux/actions/scActions.js";
import { getAllUsers } from './redux/actions/userActions';
import { getAllWorkers } from './redux/actions/workerActions.js';
import { getAllForms } from './redux/actions/formActions.js';
import { getAllDocs } from './redux/actions/docActions.js';
import { getAllExpense } from './redux/actions/expenseAction';
import { getAllTimeSheet } from './redux/actions/timeSheetActions';
import PostExpense from './pages/PostExpense';
import EditExpense from './pages/EditExpense';
import PostTimeSheet from './pages/PostTimeSheet';
import EditTimeSheet from './pages/EditTimeSheet';

function App() {
  const { loader } = useSelector(state => state.loaderReducer)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getAllSites());
    dispatch(getAllScs());
    dispatch(getAllUsers());
    dispatch(getAllWorkers());
    dispatch(getAllForms());
    dispatch(getAllDocs());
    dispatch(getAllExpense());
    dispatch(getAllTimeSheet());

  }, []);
  return (
    <div className="App">
      {loader && (<div className="sweet-loading text-center"><RingLoader color={'#157dec'} /></div>)}


      <BrowserRouter>
        <Route path="/login" exact component={Login} />
        <Route path="/register" exact component={Register} />

        {/* Post Routes */}
        <ProtectedRoute path="/" exact component={PostedSites} />
        <ProtectedRoute path="/postsite" exact component={PostSite} />
        <ProtectedRoute path="/postsc" exact component={PostSc} />
        <ProtectedRoute path="/postdoc" exact component={PostDoc} />
        <ProtectedRoute path="/empsignup" exact component={EmpSignup} />

        <ProtectedRoute path="/postworker" exact component={PostWorker} />
        <ProtectedRoute path="/postExpense" exact component={PostExpense} />
        <ProtectedRoute path="/postTimeSheet" exact component={PostTimeSheet} />

        <ProtectedRoute path="/profile" exact component={Profile} />
        <ProtectedRoute path="/jobs/:id" exact component={SiteInfo} />

        {/* Posted Routes */}
        <ProtectedRoute path="/posted" exact component={PostedSites} />
        <ProtectedRoute path="/postedScs" exact component={PostedScs} />
        <ProtectedRoute path="/postedWorkers" exact component={PostedWorkers} />
        <ProtectedRoute path="/postedForms" exact component={PostedForms} />
        <ProtectedRoute path="/postedDocs" exact component={PostedDocs} />
        <ProtectedRoute path="/postedExpenses" exact component={PostedExpenses} />
        <ProtectedRoute path="/postedTimeSheet" exact component={PostedTimeSheet} />

        {/* Edit Routes */}
        <ProtectedRoute path="/editworker/:id" exact component={EditWorker} />
        <ProtectedRoute path="/editsite/:id" exact component={EditSite} />
        <ProtectedRoute path="/editsc/:id" exact component={EditSc} />
        <ProtectedRoute path="/editdoc/:id" exact component={EditDoc} />
        <ProtectedRoute path="/editexpense/:id" exact component={EditExpense} />
        <ProtectedRoute path="/edittimesheet/:id" exact component={EditTimeSheet} />

      </BrowserRouter>
    </div>
  );
}

export default App;

export function ProtectedRoute(props) {

  const user = localStorage.getItem('user')

  if (!user) {
    return <Redirect to='/login/'>

    </Redirect>
  }
  else {
    return <Route {...props} />
  }
}
