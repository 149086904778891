import React, { useState } from 'react'
import DefaultLayout from '../components/DefaultLayout'
import { Row, Col, Form, Tabs, Input, Button, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { updateUser } from '../redux/actions/userActions'
import { editWorker, postWorker } from '../redux/actions/workerActions'
import { useHistory } from 'react-router-dom'

const { TextArea } = Input;

const { TabPane } = Tabs;

const { Option } = Select;

function EditWorker({ match }) {

    const [workerInfo, setWorkerInfo] = useState({})
    const [activeTab, setActiveTab] = useState("0")
    const dispatch = useDispatch()
    const history = useHistory()

    function onFirstFormFinish(values) {
        setWorkerInfo(values)
        setActiveTab("1")
    }

    function onFinalFormFinish(values) {
        const finalObj = { ...workerInfo, ...values };

        finalObj._id = match.params.id
        console.log(finalObj)
        dispatch(editWorker(finalObj))
    }

    const { workers } = useSelector(state => state.workersReducer)

    const worker = workers.find(worker => worker._id == match.params.id)

    return (
        <div>
            <DefaultLayout>
                <Tabs defaultActiveKey='0' activeKey={activeTab}>
                    <TabPane tab='Edit Employee' key='0'>
                        <Form onFinish={onFinalFormFinish} onReset={() => { history.push('/postedWorkers') }} initialValues={worker}>
                            <Row gutter={16}>
                                <Col lg={8} sm={24}>
                                    <Form.Item name='fname' rules={[{ required: true }]} label='First Name'>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col lg={8} sm={24}>
                                    <Form.Item name='lname' rules={[{ required: true }]} label='Last Name'>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col lg={8} sm={24}>
                                    <Form.Item name='lead' rules={[{ required: true }]} label='Is Lead: '>
                                        <Select>
                                            <Option value='True'>True</Option>
                                            <Option value='False'>False</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col lg={8} sm={24}>
                                    <Form.Item name='admin' rules={[{ required: true }]} label='Is Admin: '>
                                        <Select>
                                            <Option value='True'>True</Option>
                                            <Option value='False'>False</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col lg={8} sm={24}>
                                    <Form.Item name='phone' rules={[{ required: true }]} label='Phone:'>
                                        <Input type='number' />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col lg={8} sm={24}>
                                    <Form.Item name='email' rules={[{ required: true }]} label='Email'>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Button htmlType='submit'>Submit</Button>
                            <Button htmlType='reset' type="default" danger className="ml-2">Cancel</Button>
                        </Form>
                    </TabPane>

                </Tabs>
            </DefaultLayout>
        </div>
    )
}

export default EditWorker
