import React, { useState } from 'react'
import DefaultLayout from '../components/DefaultLayout'
import { Row, Col, Form, Tabs, Input, Button, Select, Checkbox, DatePicker } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { updateUser } from '../redux/actions/userActions'
import { postSc } from '../redux/actions/scActions'
import { useHistory } from 'react-router-dom'
import TimeDropDown from '../components/TimeDropDown'
import moment from 'moment';


const { TextArea } = Input;

const { TabPane } = Tabs;

const { Option } = Select;

function PostSc() {
    const allworkers = useSelector(state => state.workersReducer).workers;
    const allsites = useSelector(state => state.sitesReducer).sites;

    console.log(allsites);

    const [scInfo, setScInfo] = useState({})
    const [activeTab, setActiveTab] = useState("0")
    const dispatch = useDispatch()
    const history = useHistory()

    const CheckboxGroup = Checkbox.Group;

    const plainOptions = allworkers.map(x => x.fname);
    // const plainOptions = ['WorkerOne', 'WorkerTwo', 'WorkerThree', 'WorkerFour'];
    const defaultCheckedList = [];

    const [checkedList, setCheckedList] = React.useState(defaultCheckedList);
    const [indeterminate, setIndeterminate] = React.useState(true);
    const [checkAll, setCheckAll] = React.useState(false);

    const onChange = list => {
        setCheckedList(list);
        setIndeterminate(!!list.length && list.length < plainOptions.length);
        setCheckAll(list.length === plainOptions.length);
    };

    const onCheckAllChange = e => {
        setCheckedList(e.target.checked ? plainOptions : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    };


    function onFinalFormFinish(values) {
        setScInfo(values)
        const finalObj = { ...scInfo, ...values };
        console.log(finalObj)
        // finalObj.date = finalObj.date.format('dddd, MMMM Do YYYY');
        dispatch(postSc(finalObj));
    }

    return (
        <div>
            <DefaultLayout>
                <Tabs defaultActiveKey='0' activeKey={activeTab}>
                    <TabPane tab='Schedule Job' key='0'>
                        <Form onFinish={onFinalFormFinish} onReset={() => { history.push('/posted') }}>
                            <Row>
                                <Col lg={8} sm={24}>
                                    <div>
                                        <Form.Item name='appliedCandidate' label='Select Employees'>
                                            <Select
                                                defaultValue={'0'}
                                                className="mr-2"
                                                style={{ minWidth: 200 }}
                                            >
                                                <Option value="0" disabled>-- Select Employee --</Option>
                                                {allworkers.map(worker => {
                                                    return <Option value={worker.fname} key={worker.fname} >{worker.fname}</Option>
                                                })}
                                            </Select>
                                        </Form.Item>

                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={8} sm={24}>
                                    <Form.Item name='date' rules={[{ required: true }]} label='Date' initialValue={moment(new Date(), 'dddd, MMMM Do YYYY')}>
                                        <DatePicker
                                            format='dddd, MMMM Do YYYY'
                                            defaultValue={moment(new Date(), 'dddd, MMMM Do YYYY')}
                                            style={{ width: 380 }}
                                            className="mr-2"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={8} sm={24}>
                                    <Form.Item name='worksite' rules={[{ required: true }]} label='Worksite' >
                                        <Select
                                            defaultValue={'0'}
                                            className="mr-2"
                                            style={{ minWidth: 200 }}
                                        >
                                            <Option value="0" disabled>-- Select Worksite --</Option>
                                            {allsites.map(site => {
                                                return <Option value={site.siteName} key={site['_id']} >{site.siteName}</Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={8} sm={24}>
                                    <TimeDropDown name='stime' label='Start Time' />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={8} sm={24}>
                                    <TimeDropDown name='etime' label='End Time' />
                                </Col>
                            </Row>


                            <Button htmlType='submit'>Submit</Button>
                            <Button htmlType='reset' type="default" danger className="ml-2">Cancel</Button>
                        </Form>
                    </TabPane>

                </Tabs>
            </DefaultLayout>
        </div>
    )
}

export default PostSc
