import { message } from "antd";
import axios from "axios";


export const getAllExpense = () => async dispatch => {

  dispatch({ type: 'LOADING', payload: true })
  try {
    const response = await axios.get('/api/expense/getallexpenses')
    dispatch({ type: 'GET_ALL_EXPENSE', payload: response.data })
    dispatch({ type: 'LOADING', payload: false })
  } catch (error) {
    console.log(error)
    dispatch({ type: 'LOADING', payload: false })
  }
};

export const postExpense = (values) => async dispatch => {

  values.postedBy = JSON.parse(localStorage.getItem('user'))._id

  dispatch({ type: 'LOADING', payload: true })
  try {
    const response = await axios.post('/api/expense/postExpense', values)
    dispatch({ type: 'LOADING', payload: false })
    message.success('Expense added successfully')

    setTimeout(() => {
      window.location.href = '/postedExpenses'
    }, 1000);

  } catch (error) {
    console.log(error)
    dispatch({ type: 'LOADING', payload: false })
  }
};

export const editExpense = (values) => async dispatch => {

  dispatch({ type: 'LOADING', payload: true })
  try {
    const response = await axios.post("/api/expense/editExpense", values)
    dispatch({ type: 'LOADING', payload: false })
    message.success('Expense edited successfully')

    setTimeout(() => {
      window.location.href = '/postedExpenses'
    }, 1000);

  } catch (error) {
    console.log(error)
    dispatch({ type: 'LOADING', payload: false })
  }
};
